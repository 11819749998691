export const Error = () => (
    <>
        <div class="error-message-container">
            <section class="error-404">
                <h1 class="error-header">404</h1>
                <p class="error">
                    Looks like the page you requested doesn't exist.
                </p>
                <p class="error">
                    Click <a href="https://devrmateo.info/" class="error-link">here</a> to go to the
                    homepage.
                </p>
            </section>
        </div>

    </>
)